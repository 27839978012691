import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, FormErrors, Modal } from '@axeedge/go-pupil-components';
import { ArrowLeft } from 'react-feather';
import { navigate } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';
import { ADD_NEW_READING_BOOK } from '../../../../services/graphql';
import { BOOK_TYPES, getBookTypeLabel } from '../../../../../../services/constants';
import { MAIN_DASHBOARD_QUERY } from '../../../../../Dashboard/services/graphql';
import { useTranslation } from 'react-i18next';

const CreateBook = ({ studentId, close, searchTerm }) => {
    const { t } = useTranslation();

    const [confirmBook, setConfirmBook] = useState();
    const [formErrors, setFormErrors] = useState([]);

    const { register, handleSubmit, errors } = useForm({
        validationSchema: Yup.object().shape({
            title: Yup.string().required(t('please_enter_title')),
            bookType: Yup.number().typeError(t('select_book_type'))
        })
    });

    const [studentAddNewReadingBook, { loading }] = useMutation(ADD_NEW_READING_BOOK, {
        update: (_, { data }) => {
            if (data.addReadingBookAndAssignToStudent.errors && data.addReadingBookAndAssignToStudent.errors.length !== 0) {
                setFormErrors(data.addReadingBookAndAssignToStudent.errors);
                return;
            } else if (data.addReadingBookAndAssignToStudent.studentReadingBook.id) {
                navigate(`/book/${data.addReadingBookAndAssignToStudent.studentReadingBook.id}`);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MAIN_DASHBOARD_QUERY }]
    });
    
    const onAddBook = () => {
        studentAddNewReadingBook({
            variables: {
                studentId: studentId,
                author: confirmBook.author,
                title: confirmBook.title,
                hideComments: false,
                bookType: confirmBook.bookType
            }
        })
    }

    return(
        <div>
            <h1 className='icon-title'>
                <button onClick={close}  className='btn-reset u-mr-1'><ArrowLeft /></button>
                {t('create_new_book')}
             </h1>
            <p>{t('if_you_cant_find_book_add_it')}:</p>

            <form onSubmit={handleSubmit((values) => setConfirmBook(values))}>
                <div className="basic-form__group basic-form__group-block">
                    <label htmlFor='title' className='heavy basic-form__text-label'>{t('book_title')}</label>
                    <input
                        name="title"
                        placeholder={t('enter_book_title')}
                        className="basic-form__text-box"
                        ref={register}
                        defaultValue={searchTerm && searchTerm.length !== 0 && searchTerm}
                        type="text"
                    />
                </div>
                {errors.title && <p className="basic-form__hint">{errors.title.message}</p>}

                <div className="basic-form__group basic-form__group-block">
                    <label htmlFor='title' className='heavy basic-form__text-label'>{t('author')}</label>
                    <input
                        name="author"
                        placeholder={t('enter_author_name')}
                        className="basic-form__text-box"
                        ref={register}
                        type="text"
                    />
                </div>

                <div className="basic-form__group basic-form__group-block">
                    <label htmlFor='title' className='heavy basic-form__text-label'>{t('select_book_type')}</label>
                    <select ref={register} name="bookType" className="basic-form__text-select">
                        {BOOK_TYPES.filter(book => book.type !== 4).map(book => (
                            <option key={book.type} value={book.type}>{t(book.extended_label)}</option>
                        ))}
                    </select>
                </div>
                {errors.bookType && <p className="basic-form__hint">{errors.bookType.message}</p>}

                <Button type='submit' fullWidth primary>{t('add_my_book')}</Button>
            </form> 

            {confirmBook && (
                <Modal closeModal={() => setConfirmBook(null) }>
                    <h1>{t('please_check_book_details')}</h1>
                    <span className='label label-primary'>{t(getBookTypeLabel(confirmBook.bookType))}</span>
                    
                    <h2 className='u-mt-2'>{confirmBook.title}</h2>
                    {confirmBook.author && <p>{t('by')}: {confirmBook.author}</p>}

                    <div className='u-mt-3'>
                        <Button primary className='u-mr-1' onClick={() => onAddBook()}>{loading ? t('adding'): t('add_my_book')}</Button>
                        <Button outline onClick={() => setConfirmBook(null)}>{t('button.cancel')}</Button>
                    </div>

                    {formErrors.length !== 0 && <FormErrors errors={FormErrors.map(error => t(error))}/>}
                </Modal>
            )}
        </div>
    )
}

export default CreateBook;