// Queries and mutations unique to the Dashboard module
import { gql } from 'apollo-boost';

// Dashboard data
export const MAIN_DASHBOARD_QUERY = gql`
query currentStudent {
    currentStudent {
        id
        name
        avatarJson
        active
        firstName
        loginCode
        expiredToken
        boomerAvatarUrl
        updatedAt
        currentReadingRank {
            id
            readingBandRank {
                id
                name
                colourCode
            }
        }
        school {
            id
            name
            allowSeens
            allowLikes
        }
        classes {
            id
            name
            teachers {
                id
                displayName
            }
        }
        readingBooks {
            addedBy {
                id
                displayName
            }
            id
            status
            bookType
            logs {
                id
                date
            }
            book {
                id
                author
                description
                title
                coverUrl
                isbn
                isbn13
            }
        }
        groupReadingBooks {
            id
            status
            addedBy {
                id
                displayName
            }
            book {
                id
                author
                description
                title
                coverUrl
                isbn
                isbn13
            }
            logs {
                id
                date
            }
            students {
                id
            }
        }
    
    }
}
`;
